<template>
    <!-- mobile version -->
    <div class="d-block d-sm-none">
        <div class="top-banner-xs">
            <div class="row">
                <div class="col-10 col-sm-offset-1">
                    <h4 class="top-title">TEA OER Review</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-12 title text-center">
                    <h2 class="darkish-background">TEA Open Education Resources Reviewer Training</h2>
                </div>
            </div>
        </div>
        <br />

        <div v-show="authorized!='yes'">
            <div class="row col-10 offset-1">
                <div class="text-center">
                    <h4 style="margin:0 8px;"> Please enter the password provided by the OER Review project team to access the training materials.</h4>
                </div>
            </div>
            <br />
            <div class="row">
                <div class="col-8 col-sm-6 col-md-6 col-lg-4 offset-2 offset-sm-3 offset-md-3 offset-lg-4">
                    <form method="post" class="frmstyle">
                        <div class="form-group">
                            <input name="Password" type="password" v-model="password" class="form-control" placeholder="Password" aria-label="Password"
                                   v-bind:class="[(this.pwdFail) ? 'warnstyle' : 'normalstyle']" />
                        </div>
                        <br />
                        <button type="submit" class="btn btn-large actionbtn text-center" @click.prevent="Submitted">Login</button>
                    </form>
                </div>
            </div>
        </div>

        <div class="row" v-show="authorized=='denied'">
            <div class="col-sm-8 text-center">
                <br />
                <h4 style="color:red;">That is not the correct password.</h4>
            </div>
        </div>

        <div v-show="authorized=='yes'">
            <div class="row">
                <div class="col-10 offset-1">
                    <h4> Welcome to the TEA OER reviewer training and onboarding. Use the links below to view the TEA OER training video and complete the mandatory exit survey.</h4>
                </div>
            </div>
            <br />

            <div class="middle">
                <div class="row">
                    <h3 class="fontColorBlue text-center" style="margin-top:12px;">TEA OER Reviewer Training Video</h3>
                </div>
                <div class="row">
                    <iframe width=480 height=275 allowfullscreen="true" allow="autoplay *"
                            title="ESC13_Reviewer_Onboarding_and_Training.427d6d00"
                            src=https://esc13.instructuremedia.com/embed/533a2ed2-9933-4ce4-b039-2a3b9bf1e9bd frameborder="0">
                    </iframe>
                </div>
                <div class="row-cols-sm-5 offset-1">
                    <br />
                    <h3><strong>Helpful Resources</strong></h3>
                    <br />
                    <p><a href="https://jukebox.esc13.net/OER/PDFs/Reviewer-FAQ.pdf" target="_blank">Reviewer FAQ</a></p>
                    <p><a href="https://jukebox.esc13.net/OER/PDFs/ESC%2013%20Reviewer%20Onboarding%20and%20Training.pdf" target="_blank">TEA OER Training Presentation</a></p>
                    <p><a href="https://tea.texas.gov/academics/instructional-materials/tea-available-materials/tea-available-instructional-materials" target="_blank">TEA Available Instructional Materials</a></p>
                    <p><a href="https://jukebox.esc13.net/OER/PDFs/sboe-operating-rules-amended-02-02-2023.pdf" target="_blank">State Board of Education Operating Rules</a></p>
                    <p><a href="https://tea.texas.gov/academics/instructional-materials/house-bill-1605" target="_blank">House Bill 1605 Information</a></p>
                    <p><a href="https://jukebox.esc13.net/OER/PDFs/SLAR Reviewer Training Addendum 10.14.24.pdf" target="_blank">Spanish Language Arts & Reading (SLAR) Training Addendum</a></p>
                </div>
            </div>
            <br />
            <div class="row">
                <div class="col-sm-5 reviewbtn" style="margin-bottom:24px;">
                    <strong><a href="https://app.smartsheet.com/b/form/7f38b66bf0494dfdb8892944242adcb7" target="_blank">CLICK HERE: MANDATORY TEA OER REVIEWER TRAINING EXIT SURVEY</a></strong>
                </div>
                <div class="col-10 offset-1">
                    <span style="font-weight:bold;">After viewing the training video, you must complete the exit survey to be eligible for assignments. Please click the red exit survey link to begin.</span>
                </div>
            </div>
        </div>

        <br />
        <div class="row col-sm-10">
            <hr />
        </div>
        <br />
        <div class="row col-10 offset-1">
            <div class="text-center">
                <img style="height:6vh; margin-bottom: 16px;" class="img-fluid" alt="TEA logo" src="../assets/newTEA_Logo.jpg" />
                <img style="height: 4vh; margin-bottom: 16px;" class="img-fluid" src="../assets/BlackStar_Region13Logo.png" alt="ESC 13 logo" />
            </div>
            <div class="text-center">
                <span>To submit questions and/or feedback related to the OER Content Review sign up process, please <a href="https://app.smartsheet.com/b/form/f2680104a2a047b49e16b79709d2e5c9" target="_blank">follow this link</a>.</span>
            </div>
            <br />
        </div>
    </div>
    <!-- end mobile version -->

    <!-- desktop version -->
    <div class="d-none d-sm-block">
        <div class="top-banner">
            <div class="row">
                <div class="col-10 col-sm-offset-1">
                    <h4 class="top-title">TEA OER Review</h4>
                </div>
            </div>
            <div class="row">
                <div class="col-12 title text-center">
                    <h2 class="darkish-background">TEA Open Education Resources Reviewer Training</h2>
                </div>
            </div>
        </div>
        <br />

        <div v-show="authorized!='yes'">
            <div class="row">
                <div class="col-sm-8 offset-2 text-center">
                    <h4> Please enter the password provided by the OER Review project team to access the training materials.</h4>
                </div>
            </div>
            <br />
            <div class="row">
                <div class="col-8 col-sm-6 col-md-6 col-lg-4 offset-2 offset-sm-3 offset-md-3 offset-lg-4">
                    <form method="post" class="frmstyle">
                        <div class="form-group">
                            <input name="Password" type="password" v-model="password" class="form-control" placeholder="Password" aria-label="Password"
                                   v-bind:class="[(this.pwdFail) ? 'warnstyle' : 'normalstyle']" />
                        </div>
                        <br />
                        <button type="submit" class="btn btn-large actionbtn text-center" @click.prevent="Submitted">Login</button>
                    </form>
                </div>
            </div>
        </div>

        <div class="row" v-show="authorized=='denied'">
            <div class="col-sm-8 offset-2 text-center">
                <br />
                <h4 style="color:red;">That is not the correct password.</h4>
            </div>
        </div>

        <div v-show="authorized=='yes'">
            <div class="row">
                <div class="col-sm-8 offset-2 text-center">
                    <h4> Welcome to the TEA OER reviewer training and onboarding. Use the links below to view the TEA OER training video and complete the mandatory exit survey.</h4>
                </div>
            </div>
            <br />

            <div class="middle">
                <div class="row">
                    <div class="col-sm-5 offset-1 text-center">
                        <br />
                        <h3 class="fontColorBlue">TEA OER Reviewer Training Video</h3>
                        <div class="d-none d-sm-block d-md-none">
                            <iframe width=260 height=149 allowfullscreen="true" allow="autoplay *"
                                    title="ESC13_Reviewer_Onboarding_and_Training.427d6d00"
                                    src=https://esc13.instructuremedia.com/embed/533a2ed2-9933-4ce4-b039-2a3b9bf1e9bd frameborder="0">
                            </iframe>
                        </div>
                        <div class="d-none d-md-block d-lg-none">
                            <iframe width=360 height=206 allowfullscreen="true" allow="autoplay *"
                                    title="ESC13_Reviewer_Onboarding_and_Training.427d6d00"
                                    src=https://esc13.instructuremedia.com/embed/533a2ed2-9933-4ce4-b039-2a3b9bf1e9bd frameborder="0">
                            </iframe>
                        </div>
                        <div class="d-none d-lg-block">
                            <iframe width=560 height=320 allowfullscreen="true" allow="autoplay *"
                                    title="ESC13_Reviewer_Onboarding_and_Training.427d6d00"
                                    src=https://esc13.instructuremedia.com/embed/533a2ed2-9933-4ce4-b039-2a3b9bf1e9bd frameborder="0">
                            </iframe>
                        </div>

                    </div>
                    <div class="col-sm-5 offset-1">
                        <br />
                        <br />
                        <h3><strong>Helpful Resources</strong></h3>
                        <br />
                        <p><a href="https://jukebox.esc13.net/OER/PDFs/Reviewer-FAQ.pdf" target="_blank">Reviewer FAQ</a></p>
                        <p><a href="https://jukebox.esc13.net/OER/PDFs/ESC%2013%20Reviewer%20Onboarding%20and%20Training.pdf" target="_blank">TEA OER Training Presentation</a></p>
                        <p><a href="https://tea.texas.gov/academics/instructional-materials/tea-available-materials/tea-available-instructional-materials" target="_blank">TEA Available Instructional Materials</a></p>
                        <p><a href="https://jukebox.esc13.net/OER/PDFs/sboe-operating-rules-amended-02-02-2023.pdf" target="_blank">State Board of Education Operating Rules</a></p>
                        <p><a href="https://tea.texas.gov/academics/instructional-materials/house-bill-1605" target="_blank">House Bill 1605 Information</a></p>
                        <p><a href="https://jukebox.esc13.net/OER/PDFs/SLAR Reviewer Training Addendum 10.14.24.pdf" target="_blank">Spanish Language Arts & Reading (SLAR) Training Addendum</a></p>
                    </div>
                </div>
            </div>
            <br />
            <div class="row">
                <div class="col-sm-5 offset-1 reviewbtn">
                    <strong><a href="https://app.smartsheet.com/b/form/7f38b66bf0494dfdb8892944242adcb7" target="_blank">CLICK HERE: MANDATORY TEA OER REVIEWER TRAINING EXIT SURVEY</a></strong>
                </div>

                <div class="col-sm-4 offset-1">
                    <span style="font-weight:bold;">After viewing the training video, you must complete the exit survey to be eligible for assignments. Please click the red exit survey link to begin.</span>
                </div>
            </div>
        </div>

        <br />
        <div class="row col-sm-10 offset-1">
            <hr />
        </div>
        <br />
        <div class="row">
            <div class="col-sm-3 offset-2">
                <img style="height:6vh; margin-bottom: 16px;" class="img-fluid" alt="TEA logo" src="../assets/newTEA_Logo.jpg" />
                <img style="height: 4vh; margin-bottom: 16px;" class="img-fluid" src="../assets/BlackStar_Region13Logo.png" alt="ESC 13 logo" />
            </div>
            <div class="col-sm-6">
                <span>To submit questions and/or feedback related to the OER Content Review sign up process, please <a href="https://app.smartsheet.com/b/form/f2680104a2a047b49e16b79709d2e5c9" target="_blank">follow this link</a>.</span>
            </div>
        </div>
    </div>
    <!-- end desktop version -->
</template>

<script>
    import axios from 'axios';

    export default {
        name: 'Home',

        data() {
            return {
                authorized: "no"
            }
        },

        methods: {
            GetFile(fileName) {
                this.showMessage = false;
                axios({
                    url: 'api/content/getfile',
                    method: 'GET',
                    params: { "fileName": fileName },
                    responseType: 'blob'
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                });
            },

            Submitted() {
                var postData = { "Username": "genuser", "Password": this.password };
                axios.post('account/login', postData)
                    .then((res) => {
                        if (res.data == "authorized") {
                            this.authorized = "yes";
                        } else {
                            if (res.data == "denied") {
                                this.authorized = "denied";
                            }
                        }
                    });
            }
        },

        mounted() {
            document.title = "OER Texas Review";
        }
    }
</script>

<style scoped>
    hr {
        border: 2px solid grey;
        border-radius: 5px;
    }

    .frmstyle {
        text-align: center;
    }
    
    .actionbtn {
        background-color: #3771ab;
        color: #fcfcfc;
    }

    .darkish-background {
        background: rgba(0, 0, 0, 0.4);
        border-radius: 25px;
        padding: 10px;
        display: inline-block;
    }

    .top-banner {
        background-image: url(../assets/book_banner.jpg);
        background-size: cover;
        width: 100%;
        height: 24vh;
    }

    .top-banner-xs {
        background-image: url(../assets/book_banner-xs.jpg);
        background-size: cover;
        width: 100%;
        height: 24vh;
    }

    .top-title {
        color: white;
        margin: 12px 0 0 8px;
    }

    .title {
        color: white;
        margin-top: 50px;
    }

    .middle {
        background-color: #F0F0F0;
    }

    .fontColorBlue {
        color: #0078d4;
    }

    .video {
        width: 100%;
        height: auto;
    }

    .reviewbtn {
        font-size: 16px;
        background-color: #932227;
        border-radius: 10px;
        height: 50px;
        padding: 5px;
        text-align: center;
    }

    .reviewbtn a:link {
        color: white;
    }

    .reviewbtn a:visited {
        color: white;
    }

    .clickable {
        cursor: pointer;
        color: #003399;
    }

    .underline {
        text-decoration: underline;
    }

    .nudge {
        margin-left: 8px;
    }

    @media screen and (min-width: 992px) and (max-width: 1170px) {
        iframe {
            width: 480px;
            height: 275px;
        }
    }
</style>
