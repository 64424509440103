import { createStore } from 'vuex'

export default createStore({
    state: {
        loggedName: "",
        leaderName: "",
        purchaserFullName: "",
        leaderFullName: "",
        purchaserAlias: "",
        leaderAlias: "",
        viewSession: "",
        searchUser: "",
        userRole: "",
        surveys: [],
        surveyActive: true
    }
})